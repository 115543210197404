import React from "react";

const Alumini = () => {
    return (
        <div className="popup_informations">
            <div className="description">
                <p>Postdocs:</p>

                <p>
                    Bo-Yeong Won&nbsp;(Postdoc: 2014-2016)
                    <br />
                    &nbsp;&nbsp; After CCLab: Postdoc, Department of Psychology,
                    UC Davis
                    <br />
                    &nbsp;&nbsp;&nbsp;
                    <a
                        href="https://sites.google.com/site/boyeongwonpsych"
                        title="https://sites.google.com/site/boyeongwonpsych"
                    >
                        [website]
                    </a>
                    &nbsp;
                    <a
                        href="https://scholar.google.com/citations?user=e5EFDuMAAAAJ&amp;hl=en&amp;oi=ao"
                        title="https://scholar.google.com/citations?user=e5EFDuMAAAAJ&amp;hl=en&amp;oi=ao"
                    >
                        [google scholar]
                    </a>
                </p>

                <p>
                    Jessica Irons&nbsp;(Postdoc: 2014-2018)
                    <br />
                    &nbsp;&nbsp; After CCLab: Behavioural Science Specialist,
                    Australian Government
                    <br />
                    &nbsp;&nbsp;&nbsp;
                    <a
                        href="https://www.researchgate.net/profile/Jessica_Irons3"
                        title="https://www.researchgate.net/profile/Jessica_Irons3"
                    >
                        [researchgate]
                    </a>
                    &nbsp;
                    <a
                        href="https://scholar.google.com/citations?user=rzYzLKsAAAAJ&amp;hl=en&amp;oi=ao"
                        title="https://scholar.google.com/citations?user=rzYzLKsAAAAJ&amp;hl=en&amp;oi=ao"
                    >
                        [google scholar]
                    </a>
                </p>

                <p>
                    Ayala Allon&nbsp;(Postdoc: 2018-2019)
                    <br />
                    &nbsp;&nbsp; Current: Data Scientist, Retrain.ai
                    <br />
                    &nbsp;&nbsp;&nbsp;
                    <a
                        href="https://www.linkedin.com/in/ayala-s-allon-phd/"
                        title="https://www.linkedin.com/in/ayala-s-allon-phd/"
                    >
                        [linkedIn]
                    </a>
                    &nbsp;
                    <a
                        href="https://github.com/ayalaallon"
                        title="https://github.com/ayalaallon"
                    >
                        [GitHub]
                    </a>
                    &nbsp;
                    <a
                        href="https://scholar.google.com/citations?user=ZKL4dCEAAAAJ&amp;hl=en&amp;oi=ao"
                        title="https://scholar.google.com/citations?user=ZKL4dCEAAAAJ&amp;hl=en&amp;oi=ao"
                    >
                        [google scholar]
                    </a>
                </p>

                <p>Graduate Students:</p>

                <p>
                    Erika Wells&nbsp;(Ph.D. 2012)
                    <br />
                    &nbsp;&nbsp; After CCLab: Visiting Assistant Professor,
                    Union College
                    <br />
                    &nbsp;&nbsp; Current: Lecturer, Department of Psychology,
                    Boston University
                    <br />
                    &nbsp;&nbsp;&nbsp;
                    <a
                        href="https://www.bu.edu/psych/profile/erika-wells/"
                        title="https://www.bu.edu/psych/profile/erika-wells/"
                    >
                        [website]
                    </a>
                </p>

                <p>
                    Jennifer Lechak Claydon&nbsp;(Ph.D. 2013)
                    <br />
                    &nbsp;&nbsp;&nbsp;After CCLab: Director of Disability
                    Support Services, Fairfield University
                    <br />
                    &nbsp;&nbsp; Current: Assistant Director, Training
                    Assessment, Center for Teaching &amp; Learning, Yale
                    University
                </p>

                <p>Undergraduate Thesis Students:</p>

                <p>
                    Brendan Everett&nbsp;(B.A. 2010)
                    <br />
                    &nbsp;&nbsp; After CCLab: M.D. Program, University of
                    Medicine and Dentistry of NJ
                </p>

                <p>
                    Elizabeth Weber Ollen&nbsp;(B.A. 2010)
                    <br />
                    &nbsp;&nbsp; After CCLab: M.A. Program, Graduate School of
                    Education, Harvard University
                    <br />
                    &nbsp;&nbsp; Current: Ph.D. Program, Clinical Psychology,
                    Department of Psychology, Clark University
                </p>

                <p>
                    Erin Kuta&nbsp;(B.A. 2011)
                    <br />
                    &nbsp;&nbsp; After CCLab: Research Coordinator, Tufts
                    Medical Center
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    MPH/RD Program, University of North Carolina, Chapel Hill
                </p>

                <p>
                    Sarah Tower-Richardi&nbsp;(B.A. 2011)
                    <br />
                    &nbsp;&nbsp; After CCLab: Research Assistant, Tufts
                    University Department of Psychology
                    <br />
                    &nbsp;&nbsp; Current: Senior Analyst, Anderson Robbins
                    Research
                </p>

                <p>
                    Ryan O&#39;Toole&nbsp;(B.S. 2012)
                    <br />
                    &nbsp;&nbsp; After CCLab: Research Assistant, Sanford
                    Medical Center, University of South Dakota
                    <br />
                    &nbsp;&nbsp; Current: Ph.D. Program, Neuroscience, Dartmouth
                    University
                </p>

                <p>
                    Mark Scimone&nbsp;(B.S. 2013)
                    <br />
                    &nbsp;&nbsp; After CCLab: M.A. Program, Biomedical
                    Engineering, Brown University
                    <br />
                    &nbsp;&nbsp; Current:&nbsp; Ph.D. Program, Biomedical
                    Engineering, Brown University
                </p>

                <p>
                    Rebecca Freeman&nbsp;(B.S. 2016)
                    <br />
                    &nbsp;&nbsp; After CCLab: D.D.S. Program, University of
                    Michigan
                </p>

                <p>
                    Lydia Kwong&nbsp;(B.S. 2017)
                    <br />
                    &nbsp;&nbsp; After CCLab: M.A. Program, Bioethics, Duke
                    University
                </p>

                <p>
                    Walden Li&nbsp;(B.S. 2020)
                    <br />
                    &nbsp;&nbsp; Current: Research Assistant / Lab Manager,
                    CCLab
                </p>

                <p>
                    Elliot Ping&nbsp;(B.S. 2020)
                    <br />
                    &nbsp;&nbsp; After CCLab: Research Assistant, Psychology,
                    Ohio State University
                </p>

                <p>
                    Dana Shaw&nbsp;(B.S. 2020)
                    <br />
                    &nbsp;&nbsp; After CCLab: Ph.D. Program, Neuroscience,
                    Boston University
                </p>

                <p>Additional Lab Personnel (selected):</p>

                <p>
                    Rachael Gwinn&nbsp;(Full-time Research Assistant / Lab
                    Manager, 2012-2014)
                    <br />
                    &nbsp;&nbsp; After CCLab: Ph.D. Program, Department of
                    Psychology, Ohio State University
                </p>
            </div>
        </div>
    );
};

export default Alumini;
