import React, { useState } from "react";
import Skills from "../skills/Skills";
import Modal from "react-modal";
import ModalContent from "./modal/ModalContent";
import VideoLooper from "react-video-looper";

Modal.setAppElement("#root");

const About = () => {
    const [isOpen, setIsOpen] = useState(false);
    function toggleModalOne() {
        setIsOpen(!isOpen);
    }

    return (
        //    ABOUT
        <div className="edina_tm_about" id="about">
            <div className="container">
                <div className="about_title">
                    <h3>About Us</h3>
                </div>
                <div className="content">
                    <div
                        className="leftpart"
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="100"
                    >
                        <div className="info">
                            <h3>
                                We are <span>Cognitive Control Lab</span>
                            </h3>
                            <p>
                                A research lab at The Ohio State University. We
                                are interested in a range of research topics in
                                cognitive psychology.
                            </p>
                        </div>
                        <div className="my_skills">
                            <h3 className="title">What do we do?</h3>
                            <p className="desc">
                                The lab's research on cognitive control has
                                aimed to shed light on the following questions:
                                <br></br>1) How do we focus on behaviorally
                                relevant stimuli and ignore irrelevant stimuli?
                                <br></br>2) How do we choose to implement
                                control strategies, and what is the significance
                                of individual differences in such choices?
                                <br></br>3) How does our past experience guide
                                our current control strategies? <br></br>4) How
                                does our ability to engage in cognitive control
                                fluctuate, and what can we learn from these
                                fluctuations? <br></br>We have approached these
                                questions using a variety of methods,
                                principally behavioral measures, eye tracking,
                                and functional MRI.
                            </p>
                            {/* <div className="wrapper">
                                <div className="dodo_progress">
                                    <Skills />
                                </div>
                            </div> */}
                            {/* <div className="edina_tm_button">
                                <button
                                    type="submit"
                                    className="color"
                                    onClick={toggleModalOne}
                                >
                                    More About Me
                                </button>
                            </div> */}
                        </div>
                    </div>
                    {/* End leftpart */}

                    <div className="rightpart">
                        <VideoLooper
                            source={"img/about/andy_juggling.mov"}
                            start={0}
                            end={3}
                        ></VideoLooper>
                        {/* <div className="image">
                            <img src="img/thumbs/26-35.jpg" alt="thumb" />

                            <div
                                className="main"
                                style={{
                                    backgroundImage: `url(${
                                        process.env.PUBLIC_URL +
                                        "img/about/2.jpg"
                                    })`,
                                }}
                                data-aos="fade-left"
                                data-aos-duration="1200"
                                data-aos-delay="200"
                            ></div>

                            <div
                                className="experience"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                                data-aos-delay="300"
                            >
                                <div className="info">
                                    <h3>6+ Years</h3>
                                    <span>Of Experiance</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* End righttpart */}
                </div>
            </div>

            {/* Start About Details Modal */}
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModalOne}
                contentLabel="My dialog"
                className="custom-modal about-popup-wrapper"
                overlayClassName="custom-overlay "
                closeTimeoutMS={500}
            >
                <div className="edina_tm_modalbox">
                    <button className="close-modal" onClick={toggleModalOne}>
                        <img src="/img/svg/cancel.svg" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner">
                        <div className="description_wrap scrollable">
                            <ModalContent />
                        </div>
                    </div>
                    {/* End box inner */}
                </div>
                {/* End modal box news */}
            </Modal>
            {/* End  About Details Modal */}
        </div>
        // /ABOUT
    );
};

export default About;
