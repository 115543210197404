import React from "react";
import Sidebar from "../components/header/Sidebar";
import Hero from "../components/slider/Hero";
import About from "../components/about/About";
import People from "../components/people/People";
import Testimonial from "../components/testimonial/Testimonial";
import Blog from "../components/blog/Blog";
import Contact from "../components/Contact";
import Address from "../components/Address";
import Map from "../components/Map";
import Join from "../components/join/Join";
import Publication from "../components/publication/Publication";

const CCLabLight = () => {
    document.body.classList.add("light");
    return (
        <div className="home-light edina_tm_mainpart">
            <Sidebar />
            {/* End Header */}
            <Hero />
            {/* End Hero */}
            <About />
            {/* End Hero */}

            <div className="edina_tm_services" id="people">
                <div className="container">
                    <div className="edina_tm_title">
                        <h3>People</h3>
                        <p>We are a good team!</p>
                    </div>
                    {/* End edian_tm_title */}
                    <People />
                </div>
            </div>
            {/* End Services */}

            <Publication />
            {/* End Publication */}
        </div>
    );
};

export default CCLabLight;
