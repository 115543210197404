import React from "react";
// import Sidebar from "../components/header/Sidebar";

const PublicationPage = () => {
    document.body.classList.add("light");
    return (
        <div className="home-light edina_tm_mainpart">
            {/* <Sidebar /> */}
            <div className="cclab_publication">
                <div className="container">
                    <div className="cclab_title">
                        <h3>Publication</h3>
                        <p>
                            We provide PDF files as a professional courtesy to
                            ensure timely dissemination of academic for
                            individual, noncommerical purposes. Copyright and
                            all rights therein reside with the respective
                            copyright holders, as stated in each paper. These
                            files may not be reposted without permission. Thank
                            you!
                        </p>
                    </div>
                    {/* End cclab_title */}
                    <div className="publication_list">
                        <p>
                            Scotti, P. S., Hong, Y., Leber, A. B., &amp; Golomb,
                            J. D. (in press).&nbsp; Visual working memory items
                            drift apart due to active, not passive,
                            maintenance.&nbsp;&nbsp;Journal of Experimental
                            Psychology: General.
                            <br />
                            <a
                                href="https://doi.org/10.31234/osf.io/md5h4"
                                title="https://doi.org/10.31234/osf.io/md5h4"
                            >
                                [public preprint]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Scotti, P. S., Hong, Y., Golomb, J. D., &amp; Leber,
                            A. B. (2021).&nbsp; Statistical learning as a
                            reference point for memory distortions: Swap and
                            shift errors.&nbsp;&nbsp;Attention, Perception &amp;
                            Psychophysics,&nbsp;Advance Online Publication.
                            <br />
                            <a
                                href="https://rdcu.be/cdRu6"
                                title="https://rdcu.be/cdRu6"
                            >
                                [publisher]
                            </a>
                            &nbsp;
                            <a
                                href="https://doi.org/10.31234/osf.io/9pxc6"
                                title="https://doi.org/10.31234/osf.io/9pxc6"
                            >
                                [public preprint]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Clarke, A. D. F., Irons, J. L., James, W., Leber, A.
                            B., &amp; Hunt, A. R. (2020).&nbsp; Stable
                            individual differences in strategies within, but not
                            between, visual search tasks.&nbsp;&nbsp;Quarterly
                            Journal of Experimental Psychology,&nbsp;Advance
                            Online Publication.
                            <br />
                            <a
                                href="https://doi.org/10.1177/1747021820929190"
                                title="https://doi.org/10.1177/1747021820929190"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Irons, J. L. &amp; Leber (2020).&nbsp; Developing an
                            individual profile of attentional control strategy.
                            Current Directions in Psychological Science, 24 (4),
                            364-371.
                            <br />
                            <a
                                href="https://doi.org/10.1177/0963721420924018"
                                title="https://doi.org/10.1177/0963721420924018"
                            >
                                [publisher]
                            </a>
                            &nbsp;
                            <a
                                href="https://doi.org/10.31234/osf.io/5a4hx"
                                title="https://doi.org/10.31234/osf.io/5a4hx"
                            >
                                [public preprint]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Geng, J. J., Leber, A. B., &amp; Shomstein, S.
                            (2019).&nbsp; Attention and Perception:&nbsp; 40
                            review, 40 views.&nbsp;&nbsp;Current Opinion in
                            Psychology, 29,&nbsp;v-viii.
                            <br />
                            <a
                                href="https://doi.org/10.1016/j.copsyc.2019.09.001"
                                title="https://doi.org/10.1016/j.copsyc.2019.09.001"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Chen, J., Leber, A. B., &amp; Golomb, J. D.
                            (2019).&nbsp; Attentional capture alters feature
                            perception.&nbsp;&nbsp;Journal of Experimental
                            Psychology: Human Perception &amp; Performance,
                            45(11),&nbsp;1443-1454.
                            <br />
                            <a
                                href="https://cpb-us-w2.wpmucdn.com/u.osu.edu/dist/6/43793/files/2019/09/Chen_etal_2019_JEPHPP.pdf"
                                title="https://cpb-us-w2.wpmucdn.com/u.osu.edu/dist/6/43793/files/2019/09/Chen_etal_2019_JEPHPP.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="https://doi.org/10.1037/xhp0000681"
                                title="https://doi.org/10.1037/xhp0000681"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Leber, A.B. &amp; Irons, J. L. (2019).&nbsp;&nbsp;A
                            methodological toolbox for investigating attentional
                            strategy.&nbsp;Current Opinion in Psychology,
                            29,&nbsp;274-281.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/LeberIrons2019.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/LeberIrons2019.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="https://doi.org/10.1016/j.copsyc.2019.08.008"
                                title="https://doi.org/10.1016/j.copsyc.2019.08.008"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Hansen, H., Irons, J. L., &amp; Leber, A. B.
                            (2019).&nbsp; Taking stock: the role of perceptual
                            appraisal in the strategic use of attentional
                            control.&nbsp;&nbsp;Attention, Perception &amp;
                            Psychophysics, 81(8),&nbsp;2673-2684
                            <br />
                            <a
                                href="https://t.co/H0tKbgPkHH?amp=1"
                                title="https://t.co/H0tKbgPkHH?amp=1"
                            >
                                [view article online]
                            </a>
                            &nbsp;
                            <a
                                href="https://doi.org/10.3758/s13414-019-01769-6"
                                title="https://doi.org/10.3758/s13414-019-01769-6"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Gwinn, R. E., Leber, A. B., &amp; Krajbich, I.
                            (2019).&nbsp; The spillover effects of attentional
                            learning on value-based
                            choice.&nbsp;&nbsp;Cognition, 182,&nbsp;294-306.
                            <br />
                            <a
                                href="https://doi.org/10.1016/j.cognition.2018.10.012"
                                title="https://doi.org/10.1016/j.cognition.2018.10.012"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Won, B. Y. &amp; Leber, A. B. (2018).&nbsp; Failure
                            to exploit learned spatial value information during
                            visual search.&nbsp;&nbsp;Visual Cognition, 26
                            (7),&nbsp;482-499.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/WonLeber2018.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/WonLeber2018.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="https://doi.org/10.1080/13506285.2018.1500502"
                                title="https://doi.org/10.1080/13506285.2018.1500502"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Irons, J. &amp; Leber, A. B. (2018).&nbsp;
                            Characterizing individual variation in the strategic
                            use of attentional control.&nbsp;&nbsp;Journal of
                            Experimental Psychology: Human Perception &amp;
                            Performance, 44 (10),&nbsp;1637-1654.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/IronsLeber_JEPHPP_inpress.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/IronsLeber_JEPHPP_inpress.pdf"
                            >
                                [download author preprint]
                            </a>
                            &nbsp;
                            <a
                                href="https://doi.org/10.1037/xhp0000560"
                                title="https://doi.org/10.1037/xhp0000560"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Hilchey, M. D., Leber, A. B., &amp; Pratt, J.
                            (2018).&nbsp; Testing the role of response
                            repetition in priming in visual
                            search.&nbsp;&nbsp;Attention, Perception &amp;
                            Psychophysics, 80 (6),&nbsp;1362-1374.
                            <br />
                            <a
                                href="https://doi.org/10.3758/s13414-018-1550-7"
                                title="https://doi.org/10.3758/s13414-018-1550-7"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Irons, J. L., Jeon, M, &amp; Leber, A. B.
                            (2017).&nbsp; Pre-stimulus pupil dilation and the
                            preparatory control of attention.&nbsp;PLoS ONE, 12
                            (12),&nbsp;e0188787.
                            <br />
                            <a
                                href="https://doi.org/10.1371/journal.pone.0188787"
                                title="https://doi.org/10.1371/journal.pone.0188787"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Won, B. Y, &amp; Leber, A. B. (2017).&nbsp; Spatial
                            constraints on probability learning in visual
                            working memory.&nbsp;&nbsp;Visual Cognition, 25
                            (1-3),&nbsp;34-50.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/WonLeber2017.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/WonLeber2017.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1080/13506285.2017.1346738"
                                title="http://dx.doi.org/10.1080/13506285.2017.1346738"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Leber, A. B., Gwinn, R. E., Hong, Y. L., &amp;
                            O&rsquo;Toole, R. J. (2016).&nbsp; Implicitly
                            learned suppression of irrelevant spatial
                            locations.&nbsp;Psychonomic Bulletin &amp; Review,
                            23 (6),&nbsp;1873-1881.
                            <br />
                            <a
                                href="https://link.springer.com/article/10.3758/s13423-016-1065-y"
                                title="https://link.springer.com/article/10.3758/s13423-016-1065-y"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Irons, J. L., &amp; Leber, A. B. (2016).&nbsp;
                            Choosing attentional control settings in a
                            dynamically changing environment.&nbsp;Attention,
                            Perception &amp; Psychophysics, 78
                            (7),&nbsp;2031-2048.
                            <br />
                            <a
                                href="https://link.springer.com/article/10.3758%2Fs13414-016-1125-4"
                                title="https://link.springer.com/article/10.3758/s13414-016-1125-4"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Won, B. Y, &amp; Leber, A. B. (2016).&nbsp; How do
                            magnitude and frequency of monetary reward guide
                            visual search?&nbsp;&nbsp;Attention, Perception
                            &amp; Psychophysics, 78 (5),&nbsp;1221-1231.
                            <br />
                            <a
                                href="https://link.springer.com/article/10.3758%2Fs13414-016-1154-z"
                                title="https://link.springer.com/article/10.3758/s13414-016-1154-z"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Tower-Richardi, S. M., Leber, A. B., &amp; Golomb,
                            J. D. (2016).&nbsp; Spatial priming in ecologically
                            relevant reference frames.&nbsp;Attention,
                            Perception &amp; Psychophysics, 78
                            (1),&nbsp;114-132.
                            <br />
                            <a
                                href="https://link.springer.com/article/10.3758%2Fs13414-015-1002-6"
                                title="https://link.springer.com/article/10.3758/s13414-015-1002-6"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Moser, J. S., Moran, T. P., &amp; Leber, A. B.
                            (2015).&nbsp; Attentional search training improves
                            attentional control and reduces state anxiety in
                            trait anxious college students.&nbsp;Behavior
                            Therapy, 46,&nbsp;834-843.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/Moser2015.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/Moser2015.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1016/j.beth.2015.07.001"
                                title="http://dx.doi.org/10.1016/j.beth.2015.07.001"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Gibson, B. M., Leber, A. B., Mehlman, M. L.
                            (2015).&nbsp; Spatial context learning in pigeons
                            (Columba livia).&nbsp;Journal of Experimental
                            Psychology: Animal Learning &amp; Cognition, 41
                            (4),&nbsp;336-342.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/GibsonLeberMehlman2015_AuthorPreprint.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/GibsonLeberMehlman2015_AuthorPreprint.pdf"
                            >
                                [download author preprint]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1037/xan0000068"
                                title="http://dx.doi.org/10.1037/xan0000068"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Wells, E. T., &amp; Leber, A. B. (2014).
                            Motion-induced blindness is influenced by global
                            properties of the moving mask.&nbsp;Visual
                            Cognition, 22 (1),&nbsp;125-140.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/WellsLeber2014.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/WellsLeber2014.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1080/13506285.2013.875500"
                                title="http://dx.doi.org/10.1080/13506285.2013.875500"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Leber, A. B., Lechak, J. R., &amp; Tower-Richardi,
                            S. M. (2013). What do fast response times tell us
                            about attentional control?&nbsp;Journal of Vision,
                            13 (3),&nbsp;1-12.
                            <br />
                            <a
                                href="http://www.journalofvision.org/content/13/3/31"
                                title="http://www.journalofvision.org/content/13/3/31"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Lamy, D., Leber, A. B., &amp; Egeth, H. E. (2012).
                            Selective attention. In I. B. Weiner, A. F. Healy,
                            &amp; R.V. Proctor (Eds.),&nbsp;Handbook of
                            Psychology. Volume 4: Experimental Psychology (2nd
                            ed., pp. 267-294).&nbsp;New York: Wiley.
                            <br />
                            <a
                                href="mailto:domi@post.tau.ac.il?cc=leber.30@osu.edu"
                                title="mailto:domi@post.tau.ac.il?cc=leber.30@osu.edu"
                            >
                                [request reprint]
                            </a>
                            &nbsp;
                            <a
                                href="http://www.wiley.com/WileyCDA/WileyTitle/productCd-0470649933.html"
                                title="http://www.wiley.com/WileyCDA/WileyTitle/productCd-0470649933.html"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Lechak, J. R. &amp; Leber, A. B. (2012). Individual
                            differences in distraction by motion predicted by
                            neural activity in MT/V5.&nbsp;Frontiers in Human
                            Neuroscience, 6 (12),&nbsp;1-10.
                            <br />
                            <a
                                href="http://www.frontiersin.org/human_neuroscience/10.3389//fnhum.2012.00012/abstract"
                                title="http://www.frontiersin.org/human_neuroscience/10.3389//fnhum.2012.00012/abstract"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Wells, E. T., Leber, A. B., &amp; Sparrow, J. E.
                            (2011). The role of mask coherence in motion-induced
                            blindness.&nbsp;Perception, 40 (12),&nbsp;1503-1518.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/Wells2011.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/Wells2011.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1068/p6976"
                                title="http://dx.doi.org/10.1068/p6976"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Egeth, H. E., Leonard, C. J., &amp; Leber, A. B.
                            (2010). Why salience is not enough: Reflections on
                            top-down selection in vision.&nbsp;Acta
                            Psychologica, 135,&nbsp;130-132.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/Egeth2010.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/Egeth2010.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1016/j.actpsy.2010.05.012"
                                title="http://dx.doi.org/10.1016/j.actpsy.2010.05.012"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Leber, A. B. (2010). Neural predictors of
                            within-subject fluctuations in attentional
                            control.&nbsp;Journal of Neuroscience, 30
                            (34),&nbsp;11458-11465.
                            <br />
                            <a
                                href="http://www.jneurosci.org/content/30/34/11458.full.pdf+html"
                                title="http://www.jneurosci.org/content/30/34/11458.full.pdf+html"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Costello, M. C., Madden, D. J., Shepler, A. M.,
                            Mitroff, S. R., &amp; Leber, A. B. (2010).
                            Age-related preservation of top-down control over
                            distraction in visual search.&nbsp;Experimental
                            Aging Research, 36,&nbsp;249-272.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/Costello2010.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/Costello2010.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1080/0361073X.2010.484719"
                                title="http://dx.doi.org/10.1080/0361073X.2010.484719"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Leber, A. B., Kawahara, J. -I., &amp; Gabari, Y.
                            (2009). Long-term, abstract learning of attentional
                            set.&nbsp;Journal of Experimental Psychology: Human
                            Perception and Performance, 35 (5), 1385-1397.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/LeberKawaharaGabari2009.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/LeberKawaharaGabari2009.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1037/a0016470"
                                title="http://dx.doi.org/10.1037/a0016470"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Leber, A. B., Turk-Browne, N. B., &amp; Chun, M. M.
                            (2008). Neural predictors of moment-to-moment
                            fluctuations in cognitive
                            flexibility.&nbsp;Proceedings of the National
                            Academy of Sciences, USA, 105
                            (36),&nbsp;13592-13597.
                            <br />
                            <a
                                href="http://www.pnas.org/content/105/36/13592.full.pdf+html"
                                title="http://www.pnas.org/content/105/36/13592.full.pdf+html"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Folk, C. L., Leber, A. B., &amp; Egeth, H. E.
                            (2008). Top-down control settings and the
                            attentional blink: Evidence for non-spatial
                            contingent capture.&nbsp;Visual Cognition, 16
                            (5),&nbsp;616-642.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/FLE_2008.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/FLE_2008.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1080/13506280601134018"
                                title="http://dx.doi.org/10.1080/13506280601134018"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Turk-Browne, N. B., Yi, D. -J., Leber, A. B., &amp;
                            Chun, M. M.(2007). Visual quality determines the
                            direction of neural repetition
                            effects.&nbsp;Cerebral Cortex, 17,&nbsp;425-433.
                            <br />
                            <a
                                href="http://cercor.oxfordjournals.org/cgi/reprint/17/2/425"
                                title="http://cercor.oxfordjournals.org/cgi/reprint/17/2/425"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Lamy, D., Carmel, T.,&nbsp; Egeth, H. E., &amp;
                            Leber, A. B. (2006). Effects of search mode and
                            inter-trial priming on singleton
                            search.&nbsp;Perception &amp; Psychophysics, 68
                            (6),&nbsp;919-932.
                            <br />
                            <a
                                href="http://www.springerlink.com/content/c230503ln7145124/fulltext.pdf"
                                title="http://www.springerlink.com/content/c230503ln7145124/fulltext.pdf"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Leber, A. B., &amp; Egeth, H. E. (2006).&nbsp;
                            Attention on autopilot:&nbsp; Past experience and
                            attentional set.&nbsp;&nbsp;Visual Cognition,
                            14,&nbsp;565-583.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/LeberEgeth2006VC.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/LeberEgeth2006VC.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1080/13506280500193438"
                                title="http://dx.doi.org/10.1080/13506280500193438"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Leber, A. B., &amp; Egeth, H. E. (2006).&nbsp;
                            It&rsquo;s under control:&nbsp; Top-down search
                            strategies can override attentional
                            capture.&nbsp;Psychonomic Bulletin &amp; Review, 13
                            (1),&nbsp;132-138.
                            <br />
                            <a
                                href="http://www.springerlink.com/content/j831451u60u41436/fulltext.pdf"
                                title="http://www.springerlink.com/content/j831451u60u41436/fulltext.pdf"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Serences, J., Shomstein, S., Leber, A. B., Golay,
                            X., Egeth, H. E, &amp; Yantis, S. (2005).
                            Coordination of voluntary and stimulus-driven
                            attentional control in human
                            cortex.&nbsp;&nbsp;Psychological Science, 16
                            (2),&nbsp;114-122.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/serences2005.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/serences2005.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1111/j.0956-7976.2005.00791.x"
                                title="http://dx.doi.org/10.1111/j.0956-7976.2005.00791.x"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Lamy, D., Leber, A., &amp; Egeth, H. E.
                            (2004).&nbsp; Effects of stimulus-driven salience
                            within feature search mode.&nbsp;&nbsp;Journal of
                            Experimental Psychology:&nbsp; Human Perception and
                            Performance, 30 (6),&nbsp;1019-1031.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/lamy2004.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/lamy2004.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1037/0096-1523.30.6.1019"
                                title="http://dx.doi.org/10.1037/0096-1523.30.6.1019"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>
                            Folk, C. L., Leber, A. B., &amp; Egeth, H. E.
                            (2002).&nbsp; Made you blink!&nbsp; Contingent
                            attentional capture produces a spatial
                            blink.&nbsp;&nbsp;Perception &amp; Psychophysics, 64
                            (5),&nbsp;741-753.
                            <br />
                            <a
                                href="http://www.springerlink.com/content/t32038082l8145w5/fulltext.pdf"
                                title="http://www.springerlink.com/content/t32038082l8145w5/fulltext.pdf"
                            >
                                [open access article]
                            </a>
                        </p>

                        <p>
                            Egeth, H. E., Folk, C. L., Leber, A. B., Nakama, T.,
                            &amp; Hendel, S. (2001).&nbsp; Attentional capture
                            in the spatial and temporal domains.&nbsp; In C. L.
                            Folk &amp; B. S. Gibson (Eds.)&nbsp;Advances in
                            Psychology XXX - Attraction, Distraction, and
                            Action:&nbsp; Multiple Perspectives on Attentional
                            Capture. (pp. 93-119).&nbsp;Amsterdam:&nbsp;
                            Elsevier Science B. V.
                            <br />
                            <a
                                href="https://www.asc.ohio-state.edu/psychology/cclab/downloads/Egeth2001_precopyedited.pdf"
                                title="https://www.asc.ohio-state.edu/psychology/cclab/downloads/Egeth2001_precopyedited.pdf"
                            >
                                [download article]
                            </a>
                            &nbsp;
                            <a
                                href="http://dx.doi.org/10.1016/S0166-4115(01)80007-8"
                                title="http://dx.doi.org/10.1016/S0166-4115(01)80007-8"
                            >
                                [publisher]
                            </a>
                        </p>

                        <p>&nbsp;</p>

                        <p>Recent Conference Presentations</p>

                        <p>
                            Li, W.Y., McKinney, M. R., Irons, J. L., &amp;
                            Leber, A. B. (2020, November).&nbsp; How universal
                            is suboptimal visual search strategy?&nbsp; Poster
                            presented at the 28th&nbsp;Annual Object Perception,
                            Attention, and Memory (OPAM) Workshop.&nbsp;&nbsp;
                            <a
                                href="https://opam.net/"
                                title="https://opam.net"
                            >
                                https://opam.net
                            </a>
                        </p>

                        <p>
                            McKinney, M. R., Irons, J. L., &amp; Leber, A. B.
                            (2020, November).&nbsp; Motivating strategic
                            attentional control with monetary reward.&nbsp;
                            Poster presented at the 28th&nbsp;Annual Object
                            Perception, Attention, and Memory (OPAM)
                            Workshop.&nbsp;&nbsp;
                            <a
                                href="https://opam.net/"
                                title="https://opam.net"
                            >
                                https://opam.net
                            </a>
                        </p>

                        <p>
                            Dube, B., Leber, A. B., &amp; Golomb, J. D. (2020,
                            June).&nbsp; Perceptual distraction disrupts control
                            over visual memory encoding.&nbsp; Poster presented
                            at the Annual Meeting of the Vision Sciences
                            Society.&nbsp;&nbsp;
                            <a
                                href="https://visionsciences.org/"
                                title="https://visionsciences.org"
                            >
                                https://visionsciences.org
                            </a>
                        </p>

                        <p>
                            Ping, E. E. C., Heisterberg, L. M., Allon, A. S.,
                            &amp; Leber, A. B. (2020, June).&nbsp; Investigating
                            how illusory objects are represented in visual
                            working memory.&nbsp; Poster presented at the Annual
                            Meeting of the Vision Sciences Society.&nbsp;&nbsp;
                            <a
                                href="https://visionsciences.org/"
                                title="https://visionsciences.org"
                            >
                                https://visionsciences.org
                            </a>
                        </p>

                        <p>
                            Shaw, D. C., Hansen, H. A., McKinney, M. R., Irons,
                            J. L., &amp; Leber, A. B. (2020, June).&nbsp; Does
                            task switching ability predict the selection of
                            attentional control strategies?&nbsp; Poster
                            presented at the Annual Meeting of the Vision
                            Sciences Society.&nbsp;&nbsp;
                            <a
                                href="https://visionsciences.org/"
                                title="https://visionsciences.org"
                            >
                                https://visionsciences.org
                            </a>
                        </p>

                        <p>
                            Li, W., McKinney, M. R., Irons, J. L., &amp; Leber,
                            A. B. (2020, June).&nbsp; Exploring the
                            generalizability of visual search strategy.&nbsp;
                            Poster presented at the Annual Meeting of the Vision
                            Sciences Society.&nbsp;&nbsp;
                            <a
                                href="https://visionsciences.org/"
                                title="https://visionsciences.org"
                            >
                                https://visionsciences.org
                            </a>
                        </p>

                        <p>
                            McKinney, M. R., Irons, J. L., &amp; Leber, A. B.
                            (2020, June).&nbsp; Monetary reward motivates the
                            adoption of optimal attentional control
                            strategies.&nbsp; Poster presented at the Annual
                            Meeting of the Vision Sciences Society.&nbsp;&nbsp;
                            <a
                                href="https://visionsciences.org/"
                                title="https://visionsciences.org"
                            >
                                https://visionsciences.org
                            </a>
                        </p>

                        <p>
                            Allon, A. S., &amp; Leber, A. B. (2019,
                            November).&nbsp; Experience-driven suppression of
                            irrelevant distractor locations is context
                            dependent.&nbsp; Paper presented at the Annual
                            Meeting of the Psychonomic Society, Montr&eacute;al,
                            QC.
                        </p>

                        <p>
                            Heisterberg, L. M., &amp; Leber, A. B. (2019,
                            November).&nbsp; Spatial demands after target search
                            interfere with contextual cueing.&nbsp; Poster
                            presented at the 27th&nbsp;Annual Object Perception,
                            Attention, and Memory (OPAM) Workshop,
                            Montr&eacute;al, QC.
                        </p>

                        <p>
                            McKinney, M. R., Hansen, H. A., Irons, J. L., &amp;
                            Leber, A. B. (2019, November).&nbsp; An exploration
                            of ability and personality trait variables
                            predicting goal-directed attention in visual
                            search.&nbsp; Poster presented at the
                            27th&nbsp;Annual Object Perception, Attention, and
                            Memory (OPAM) Workshop, Montr&eacute;al, QC.
                        </p>

                        <p>
                            Ping, E. E. C., Heisterberg, L. M., Allon, A.
                            S.,&nbsp; &amp; Leber, A. B. (2019, November).&nbsp;
                            How are illusory objects represented in visual
                            working memory?&nbsp; Poster presented at the
                            27th&nbsp;Annual Object Perception, Attention, and
                            Memory (OPAM) Workshop, Montr&eacute;al, QC.
                        </p>

                        <p>
                            Scotti, P. S., Hong, Y., Leber, A. B., &amp; Golomb,
                            J. D. (2019, November).&nbsp; Competition between
                            similar visual working memory items underlies
                            repulsion.&nbsp; Paper presented at the
                            27th&nbsp;Annual Object Perception, Attention, and
                            Memory (OPAM) Workshop, Montr&eacute;al, QC.
                        </p>

                        <p>
                            Heisterberg, L. M., &amp; Leber, A. B. (2019,
                            July).&nbsp; Optimality of tool selection in
                            radiologists and na&iuml;ve subjects.&nbsp; Paper
                            presented at the Medical Image Perception Society
                            XVIII, Salt Lake City, UT.
                        </p>

                        <p>
                            Scotti, P., Hong, Y., Leber, A., &amp; Golomb, J.
                            (2019, May).&nbsp; Relational interactions between
                            visual memory representations increase with
                            maintenance duration.&nbsp; Poster presented at the
                            Annual Meeting of the Vision Sciences Society, St.
                            Pete Beach, FL.
                        </p>

                        <p>
                            Hong, Y., Maxcey, A., &amp; Leber, A. (2019,
                            May).&nbsp; Recognition-induced forgetting of
                            temporally related visual long-term memories.&nbsp;
                            Poster presented at the Annual Meeting of the Vision
                            Sciences Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            McKinney, M., Hansen, H., Irons, J., &amp; Leber, A.
                            (2019, May).&nbsp; An exploration of trait variables
                            predicting the goal-directed control of visual
                            attention.&nbsp; Poster presented at the Annual
                            Meeting of the Vision Sciences Society, St. Pete
                            Beach, FL.
                        </p>

                        <p>
                            Heisterberg, L., &amp; Leber A. (2019, May).&nbsp; A
                            secondary task stunts the development of contextual
                            cueing.&nbsp; Poster presented at the Annual Meeting
                            of the Vision Sciences Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            Hong, Y., Maxcey, A. M., &amp; Leber, A. B. (2018,
                            November). Recognition-induced forgetting of
                            temporally related visual episodic memories.&nbsp;
                            Poster presented at the Annual Meeting of the
                            Psychonomic Society, New Orleans, LA.
                        </p>

                        <p>
                            Scotti, P. S., Hong, Y., Golomb, J. D., &amp; Leber,
                            A. B. (2018, November). Statistical regularities
                            during object encoding distort long-term
                            memory.&nbsp; Poster presented at the
                            26th&nbsp;Annual Object Perception, Attention, and
                            Memory (OPAM) Workshop, New Orleans, LA.
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            Heisterberg, L. M. &amp; Leber, A. B. (2018,
                            November). Retroactive stunting of contextual
                            cueing.&nbsp; Poster presented at the
                            26th&nbsp;Annual Object Perception, Attention, and
                            Memory (OPAM) Workshop, New Orleans, LA.
                        </p>

                        <p>
                            Chen, J., Leber, A., &amp; Golomb, J. D. (2018,
                            November).&nbsp; Attentional capture alters feature
                            perception.&nbsp; Paper presented at the
                            26th&nbsp;Annual Object Perception, Attention, and
                            Memory (OPAM) Workshop, New Orleans, LA.
                        </p>

                        <p>
                            Chen, J., Leber, A., &amp; Golomb, J. (2018,
                            May).&nbsp; Errors without doubt: Stimulus-driven
                            attentional capture leads to feature-binding errors
                            but no loss in confidence.&nbsp; Poster presented at
                            the Annual Meeting of the Vision Sciences Society,
                            St. Pete Beach, FL.
                        </p>

                        <p>
                            Irons, J, &amp; Leber, A. (2018, May).&nbsp;
                            Ignorance vs. laziness:&nbsp; Why do people use
                            suboptimal attentional control strategies?&nbsp;
                            Poster presented at the Annual Meeting of the Vision
                            Sciences Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            Hansen, H., Irons, J., &amp; Leber, A. (2018,
                            May).&nbsp; A secondary task leads to poorer
                            selection of attentional control strategies.&nbsp;
                            Poster presented at the Annual Meeting of the Vision
                            Sciences Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            Heisterberg, L., Hong, Y., &amp; Leber A. (2018,
                            May).&nbsp; The impact of interruptions on long-term
                            object processing.&nbsp; Poster presented at the
                            Annual Meeting of the Vision Sciences Society, St.
                            Pete Beach, FL.
                        </p>

                        <p>
                            Hong, Y. &amp; Leber, A. (2018, May).&nbsp; When
                            does implicitly-learned spatial context bias
                            attention?&nbsp; Poster presented at the Annual
                            Meeting of the Vision Sciences Society, St. Pete
                            Beach, FL.
                        </p>

                        <p>
                            Scotti, P., Hong, Y., Golomb, J., &amp; Leber, A.
                            (2018, May).&nbsp; Statistical regularities during
                            object encoding distort long-term memory.&nbsp;
                            Poster presented at the Annual Meeting of the Vision
                            Sciences Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            Irons, J. &amp; Leber, A. (2017, November).&nbsp; A
                            trade-off between performance and effort in the
                            choice of attentional control settings.&nbsp; Paper
                            presented at the 25th&nbsp;Annual Object Perception,
                            Attention, and Memory (OPAM) Workshop, Vancouver,
                            BC.
                        </p>

                        <p>
                            Hong, Y. &amp; Leber, A. (2017, November).&nbsp;
                            Spontaneous biasing toward implicitly-learned visual
                            regularities:&nbsp; the role of prior attention.
                            Poster presented at the 25th&nbsp;Annual Object
                            Perception, Attention, and Memory (OPAM) Workshop,
                            Vancouver, BC.
                        </p>

                        <p>
                            Heisterberg, L., Hong, Y. &amp; Leber, A. (2017,
                            November).&nbsp; How do task interruptions affect
                            ongoing object processing? Poster presented at the
                            25th&nbsp;Annual Object Perception, Attention, and
                            Memory (OPAM) Workshop, Vancouver, BC.
                        </p>

                        <p>
                            Clarke, A. D. F., Irons, J., Rigitano, C., Leber,
                            A., Hunt, A. R. (2017, August).&nbsp; Stable visual
                            search strategies within but not between visual
                            search paradigms.&nbsp; Talk presented at the
                            40th&nbsp;European Conference on Visual Perception,
                            Berlin, Germany.
                        </p>

                        <p>
                            Hong, Y. &amp; Leber, A. B. (2017, May).&nbsp;
                            Spontaneous biasing toward implicitly-learned visual
                            regularities:&nbsp; the role of prior attention.
                            Poster to be presented at the Annual Meeting of the
                            Vision Sciences Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            Irons, J. I. &amp; Leber, A. B. (2017, May).&nbsp; A
                            trade-off between performance and effort in the
                            choice of attentional control settings.&nbsp; Poster
                            to be presented at the Annual Meeting of the Vision
                            Sciences Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            Leber, A. B. &amp; Irons, J. I. (2017, May).&nbsp;
                            Toward a profile of goal-directed attentional
                            control.&nbsp; Paper to be presented at the special
                            symposium, &ldquo;Cutting across the
                            top-down/bottom-up dichotomy in attentional capture
                            research,&rdquo; at the Annual Meeting of the Vision
                            Sciences Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            Irons, J. I., &amp; Leber, A. B. (2016,
                            November).&nbsp; Strategic control of goal-directed
                            attention.&nbsp; Paper presented at the Annual
                            Meeting of the Psychonomic Society, Boston, MA.
                        </p>

                        <p>
                            Irons, J. I., &amp; Leber, A. B. (2016,
                            November).&nbsp; Prestimulus pupil dilation predicts
                            the degree of preparatory attentional control.&nbsp;
                            Poster presented at the annual Object Perception,
                            Attention, and Memory (OPAM) Workshop, Boston, MA.
                        </p>

                        <p>
                            Won, B. Y., &amp; Leber, A. B. (2016,
                            November).&nbsp; Implicit learning interacts with
                            hemifield independence in visual working
                            memory.&nbsp; Poster presented at the annual Object
                            Perception, Attention, and Memory (OPAM) Workshop,
                            Boston, MA.
                        </p>

                        <p>
                            Irons, J., &amp; Leber, A. (2016, May).&nbsp;
                            Establishing an individual profile of attentional
                            control.&nbsp; Micro-talk presented at the
                            Individual Differences in Vision Satellite Session
                            of the Annual Meeting of the Vision Sciences
                            Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            Hong, Y., &amp; Leber, A. (2016, May).&nbsp;
                            Attentional disengagement suppresses visual
                            long-term memory.&nbsp; Poster presented at the
                            Annual Meeting of the Vision Sciences Society, St.
                            Pete Beach, FL.
                        </p>

                        <p>
                            Irons, J., &amp; Leber, A. (2016, May).&nbsp; Pupil
                            dilation indexes effort exertion during the
                            configuration of attentional control setting.&nbsp;
                            Poster presented at the Annual Meeting of the Vision
                            Sciences Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            Leber, A., &amp; Won, B. (2016, May).&nbsp; Spatial
                            reward guides choice, not visual search.&nbsp; Paper
                            presented at the Annual Meeting of the Vision
                            Sciences Society, St. Pete Beach, FL.
                        </p>

                        <p>
                            Won, B., &amp; Leber, A. (2016, May).&nbsp; Search
                            for targets in visual working memory is biased by
                            statistical learning.&nbsp; Poster presented at the
                            Annual Meeting of the Vision Sciences Society, St.
                            Pete Beach, FL.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicationPage;
