import React from "react";
import ReactTooltip from "react-tooltip";

const FeaturedPublication = ({
    imgLink,
    targLink,
    tipId,
    tipTitle,
    tipSubTitle,
}) => {
    return (
        <li>
            <div className="list_inner">
                <div className="image">
                    <a
                        href={targLink}
                        target="_blank"
                        rel="noreferrer"
                        className="details"
                    >
                        <img
                            src={imgLink}
                            data-tip
                            data-for={tipId}
                            alt="portfolio"
                        />
                        <ReactTooltip
                            id={tipId}
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                        >
                            <div>
                                <h5>{tipTitle}</h5>
                                <span>{tipSubTitle}</span>
                            </div>
                        </ReactTooltip>
                    </a>
                </div>
            </div>
        </li>
    );
};

export default FeaturedPublication;
