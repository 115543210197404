import React, { useState } from "react";
import Modal from "react-modal";
import Person from "./Person";
import Alumini from "./Alumini";

Modal.setAppElement("#root");

const People = () => {
    const [isOpen, setIsOpen] = useState(false);

    function toggleAlumini() {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <div className="service_list">
                <ul>
                    <Person
                        name="Andy Leber"
                        position="Principal Investigator"
                        imgLink="img/people/andy_leber.png"
                        more="leber.30@osu.edu"
                    ></Person>
                    <Person
                        name="Seah Chang"
                        position="Post-Doc Researcher"
                        imgLink="img/people/seah_chang.png"
                    ></Person>
                    <Person
                        name="Yoolim Hong"
                        position="Graduate Student"
                        imgLink="img/people/yoolim_hong.png"
                    ></Person>
                    <Person
                        name="Lisa Heisterberg"
                        position="Graduate Student"
                        imgLink="img/people/lisa_ heisterberg.jpeg"
                    ></Person>
                    <Person
                        name="Heather Hansen"
                        position="Graduate Student"
                        imgLink="img/people/heather_hansen.jpeg"
                    ></Person>
                    <Person
                        name="Paul Scotti"
                        position="Graduate Student"
                        imgLink="img/people/paul_scotti.jpeg"
                    ></Person>
                    <Person
                        name="Tianyu Zhang"
                        position="Graduate Student"
                        imgLink="img/people/tianyu_zhang.jpeg"
                    ></Person>
                    <Person
                        name="Jenny Lin"
                        position="Graduate Student"
                        imgLink="img/people/jenny_lin.png"
                    ></Person>
                    <Person
                        name="Molly McKinney"
                        position="Lab Manager"
                        imgLink="img/people/molly_mckinney.jpeg"
                    ></Person>
                    <Person
                        name="Walden Li"
                        position="Lab Manager"
                        imgLink="img/people/walden_li.jpeg"
                    ></Person>
                    <Person
                        name="Hannah Ackley"
                        position="Undergrad RA"
                        imgLink="img/people/hannah_ackley.jpeg"
                    ></Person>
                    <Person
                        name="Ben Dominguez"
                        position="Undergrad RA"
                        imgLink="img/people/ben_dominguez.jpeg"
                    ></Person>
                    <Person
                        name="Aliya Elkon"
                        position="Undergrad RA"
                        imgLink="img/people/aliya_elkon.jpeg"
                    ></Person>
                    <Person
                        name="Saachi Kuthe"
                        position="Undergrad RA"
                        imgLink="img/people/saachi_kuthe.jpeg"
                    ></Person>
                    <Person
                        name="Payton Messersmith"
                        position="Undergrad RA"
                        imgLink="img/people/payton_messersmith.jpeg"
                    ></Person>
                    <Person
                        name="Phe Rodriguez"
                        position="Undergrad RA"
                        imgLink="img/people/phe_rodriguez.jpeg"
                    ></Person>
                    <Person
                        name="Ana Roman"
                        position="Undergrad RA"
                        imgLink="img/people/ana_roman.jpeg"
                    ></Person>
                    <Person
                        name="Janhavi Sahasrabudhe"
                        position="Undergrad RA"
                        imgLink="img/people/janhavi_sahasrabudhe.jpeg"
                    ></Person>
                    <Person
                        name="Mackenzie Siesel"
                        position="Undergrad RA"
                        imgLink="img/people/mackenzie_siesel.jpeg"
                    ></Person>
                    <Person
                        name="Mochi"
                        position="Spiritual Guide"
                        imgLink="img/people/mochi.jpeg"
                        more="A hound, Mochi is the spiritual guide of both Cognitive Control Lab (Leberatory) and Visual Cognitive Neuroscience Lab (Golomb Lab)."
                    ></Person>
                </ul>
                <div
                    className="edina_tm_button"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="400"
                    onClick={toggleAlumini}
                >
                    <button>Lab Alumini</button>
                </div>
            </div>
            {/* Start Modal One */}
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleAlumini}
                contentLabel="My dialog"
                className="custom-modal"
                overlayClassName="custom-overlay"
                closeTimeoutMS={500}
            >
                <div className="edina_tm_modalbox">
                    <button className="close-modal" onClick={toggleAlumini}>
                        <img src="/img/svg/cancel.svg" alt="close icon" />
                    </button>
                    {/* End close icon */}

                    <div className="box_inner">
                        <Alumini />
                    </div>
                    {/* End box inner */}
                </div>
                {/* End modal box news */}
            </Modal>
            {/* End modal One */}
        </>
    );
};

export default People;
