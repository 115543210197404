import React from "react";

const Person = ({ name, position, imgLink, more = undefined }) => {
    return (
        <li data-aos="fade-right" data-aos-duration="1200">
            <div className="list_inner">
                <div className="hover">
                    <img className="people_head" src={imgLink} alt="" />
                    <div className="people_name">
                        <h3>{name}</h3>
                    </div>
                    <div className="learn_more">
                        {position}
                        {/* <span></span> */}
                        <div className="more">{more}</div>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default Person;
