import React from "react";
import Social from "../Social";
import Typed from "typed.js";

const heroContent = {
    shapeImage: "hero-shape",
    heroImage: "2",
    name: "Cognitive Control Lab",
    description: "We Study ",
    typed_list: [
        "Attention.",
        "Perception.",
        "Psychophysics.",
        "Strategy.",
        "Strategy Optimization.",
        "Visual Search.",
        "Visual Foraging.",
        "Functional MRI.",
        "Cognitive Control.",
    ],
};

const Hero = () => {
    // Create reference to store the DOM element containing the animation
    const el = React.useRef(null);
    // Create reference to store the Typed instance itself
    const typed = React.useRef(null);
    React.useEffect(() => {
        const options = {
            strings: heroContent.typed_list,
            typeSpeed: 50,
            backSpeed: 50,
        };

        // elRef refers to the <span> rendered below
        typed.current = new Typed(el.current, options);

        return () => {
            // Make sure to destroy Typed instance during cleanup
            // to prevent memory leaks
            typed.current.destroy();
        };
    }, []);

    return (
        //    HERO
        <div className="edina_tm_hero" id="home">
            <svg
                className="hero_svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMinYMin meet"
            >
                <svg>
                    <rect
                        x="26.30591817307929"
                        y="22.1460000447345"
                        width="4"
                        height="4"
                        fill="rgb(0, 0, 255)"
                    ></rect>
                </svg>
                <rect
                    x="69.6940818269207"
                    y="22.146000044734485"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="47.99999999999999"
                    y="14.25"
                    width="4"
                    height="4"
                    fill="rgb(0, 150, 0)"
                ></rect>
                <rect
                    x="26.305918173079295"
                    y="73.85399995526551"
                    width="4"
                    height="4"
                    fill="rgb(0, 150, 0)"
                ></rect>
                <rect
                    x="18.771642622275202"
                    y="64.87500000000001"
                    width="4"
                    height="4"
                    fill="rgb(0, 150, 0)"
                ></rect>
                <rect
                    x="59.64685702961347"
                    y="91.46666218300805"
                    width="4"
                    height="4"
                    fill="rgb(0, 150, 0)"
                ></rect>
                <rect
                    x="48"
                    y="93"
                    width="4"
                    height="4"
                    fill="rgb(0, 150, 0)"
                ></rect>
                <rect
                    x="70.50000000000003"
                    y="86.97114317029973"
                    width="4"
                    height="4"
                    fill="rgb(0, 150, 0)"
                ></rect>
                <rect
                    x="48"
                    y="81.75"
                    width="4"
                    height="4"
                    fill="rgb(0, 150, 0)"
                ></rect>
                <rect
                    x="59.646857029613415"
                    y="4.533337816991924"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="25.5"
                    y="48"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="36.45682016275868"
                    y="79.71462595152441"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="77.22835737772479"
                    y="31.124999999999986"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="36.75000000000001"
                    y="67.48557158514987"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="81.23726166416202"
                    y="42.139374003741096"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="77.2283573777248"
                    y="64.875"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="47.99999999999999"
                    y="25.5"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="67.48557158514987"
                    y="36.749999999999986"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="9.028856829700253"
                    y="25.500000000000014"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="47.99999999999999"
                    y="3"
                    width="4"
                    height="4"
                    fill="rgb(255, 0, 0)"
                ></rect>
                <rect
                    x="18.771642622275188"
                    y="31.125000000000014"
                    width="4"
                    height="4"
                    fill="rgb(0, 0, 255)"
                ></rect>
                <rect
                    x="86.97114317029975"
                    y="70.49999999999997"
                    width="4"
                    height="4"
                    fill="rgb(0, 0, 255)"
                ></rect>
                <rect
                    x="91.46666218300805"
                    y="36.35314297038653"
                    width="4"
                    height="4"
                    fill="rgb(0, 0, 255)"
                ></rect>
            </svg>
            <div className="content">
                {/* <div
                    className="img-shape"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                >
                    <img
                        src={`img/hero/${heroContent.shapeImage}`}
                        srcSet={`img/hero/${heroContent.heroImage}.jpg`}
                        alt="hero image"
                    />
                </div> */}
                <div className="extra">
                    <h1
                        className="name"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="100"
                    >
                        {heroContent.name}
                    </h1>
                    <p
                        className="text"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                    >
                        {heroContent.description}
                        <span style={{ whiteSpace: "pre" }} ref={el} />
                    </p>

                    {/* <div
                        className="social"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="300"
                    >
                        <Social />
                    </div> */}
                    <div
                        className="edina_tm_button"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="400"
                    >
                        <a href="#about" className="color">
                            Learn More
                        </a>
                    </div>
                </div>
            </div>
        </div>
        // /HERO
    );
};

export default Hero;
