import React from "react";
import CCLabLight from "../views/CCLabLight";
import HomeDark from "../views/all-home-version/HomeDark";
import PublicationsPage from "../views/PublicationsPage";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = () => {
    return (
        <>
            <Router>
                <ScrollTopBehaviour />
                <Switch>
                    <Route exact path="/" component={CCLabLight} />
                    <Route path="/publication" component={PublicationsPage} />
                    <Route component={NotFound} />
                </Switch>
            </Router>
        </>
    );
};

export default Routes;
