import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FeaturedPublication from "./FeaturedPublication";

const Publication = () => {
    const sliderSettings = {
        dots: false,
        arrow: true,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    arrow: false,
                    autoplay: false,
                    draggable: true,
                    speed: 300,
                    dots: true,
                },
            },
        ],
    };

    return (
        <div className="cclab_publication" id="publication">
            <div className="container">
                <div className="cclab_title">
                    <h3>Publication</h3>
                    <p>
                        See a complete list of publication from Cognitive
                        Control Lab here.
                    </p>
                </div>
                <div
                    className="cclab_button"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="400"
                >
                    <a href="/publication">Complete List of Publications</a>
                </div>
                <div className="portfolio_inner my_carousel gallery_zoom">
                    <ul data-aos="fade-right" data-aos-duration="1200">
                        <Slider {...sliderSettings}>
                            <FeaturedPublication
                                imgLink="img/featured/li2022.png"
                                targLink="https://psyarxiv.com/jvube/"
                                tipId="li2022"
                                tipTitle='"Heterogeneity of attentional control strategy optimization"'
                                tipSubTitle="JEP:HPP (in press)"
                            ></FeaturedPublication>
                            <FeaturedPublication
                                imgLink="img/featured/scotti2022.png"
                                targLink="https://psyarxiv.com/md5h4/"
                                tipId="scotti2022"
                                tipTitle='"Active maintenance causes VWM items drift apart"'
                                tipSubTitle="JEP:G (in press)"
                            ></FeaturedPublication>
                            <FeaturedPublication
                                imgLink="img/featured/clarke2022.png"
                                targLink="https://journals.sagepub.com/doi/10.1177/1747021820929190"
                                tipId="clarke2022"
                                tipTitle='"No correlation between three VS tasks"'
                                tipSubTitle="QJEP (2022)"
                            ></FeaturedPublication>
                            <FeaturedPublication
                                imgLink="img/featured/hansen2019.png"
                                targLink="https://link.springer.com/article/10.3758/s13414-019-01769-6"
                                tipId="hansen2019"
                                tipTitle='"Appraisal is important to strategy optimality"'
                                tipSubTitle="AP&P (2019)"
                            ></FeaturedPublication>
                        </Slider>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Publication;
